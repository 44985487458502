let sliderClass = "j-featured-jobs--slick";

var initializeBlock = function($block, $attributes) {
	let elem = $block;
	if (!$block.hasClass(sliderClass)) {
		elem = $block.find("." + sliderClass);
	}

	let parent_container = elem.closest('.ao-featured-jobs').attr('id');
	parent_container = '#' + parent_container;

	elem.each(function() {
		$(this).slick({
			'slide' : '.ao-job-excerpt',
			'rows' : 0,
			'slidesToShow' : 3,
			'slidesToScroll' : 1,
			'infinite' : false,
			'speed' : 200,
			'dots' : false,
			'arrows' : true,
			'autoplay' : false,
			'fade' : false,
			'adaptiveHeight' : true,
			'draggable' : false,
			'vertical' : true,
			'prevArrow' : '<span class="ao-featured-jobs--slick--prev"><svg xmlns="http://www.w3.org/2000/svg" width="10.316px" height="16.471px" viewBox="0.127 0.529 10.316 16.471"><path fill="#CD0E2C" d="M10.443,2.465l-6.376,6.3l6.376,6.3L8.48,17L0.127,8.765L8.48,0.529L10.443,2.465z"/></svg></span>',
			'nextArrow' : '<span class="ao-featured-jobs--slick--next"><svg xmlns="http://www.w3.org/2000/svg" width="10.316px" height="16.471px" viewBox="0.127 0.529 10.316 16.471"><path fill="#CD0E2C" d="M0.127,15.064l6.375-6.3l-6.375-6.3L2.09,0.529l8.353,8.235L2.09,17L0.127,15.064z"/></svg></span>',
			'appendArrows' : parent_container + ' .ao-featured-jobs--slick--custom-nav',
		});
	});

	let maxHeight = -1;
	$(parent_container + ' .ao-job-excerpt').each(function() {
		if ($(this).height() > maxHeight) {
			maxHeight = $(this).height();
		}
	});
	$(parent_container + ' .ao-job-excerpt').each(function() {
		if ($(this).height() < maxHeight) {
			$(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
		}
	});
};

// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + sliderClass).each(function() { 
		initializeBlock($(this), false);
	});
});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=featured-jobs", initializeBlock);
}